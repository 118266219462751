<div ngbDropdown #dropdown="ngbDropdown" placement="bottom-right" container="body" (click)="$event.stopPropagation(); $event.preventDefault()">
  <button class="btn btn-secondary btn-sm" id="actions-dropdown-{{ id }}" ngbDropdownToggle>
    Options
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="'actions-dropdown-' + id">
    @if (dropdown.isOpen()) {
      <ng-content></ng-content>
    }
  </div>
</div>
