<div ngbDropdown placement="top-end" class="d-inline-block">
  <button type="button" class="btn btn-lg btn-primary btn-floating-action rounded-circle bi-camera" id="dropdownBasic2"
          ngbDropdownToggle></button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
    <label ngbDropdownItem for="upload" class="dropdown-item-text bi-upload">
      Upload
    </label>
    <input #uploadInput class="visually-hidden" type="file" id="upload" accept="image/*"
           (change)="uploadPhoto(uploadInput.files)">
    <label ngbDropdownItem for="capture" class="dropdown-item-text bi-camera">
      Capture
    </label>
    <input #captureInput class="visually-hidden" type="file" id="capture" accept="image/*" capture="environment"
           (change)="uploadPhoto(captureInput.files)">
  </div>
</div>
