<div class="row g-0 h-100">
  <div class="col-lg-3 h-100 overflow-auto master" style="margin-bottom: 20px;" routerLinkActive="master-active"
    [routerLinkActiveOptions]="{exact: true}">
    <a class="d-none" routerLink="."></a>
    <ng-content select="[breadcrumb]"></ng-content>
    <ng-content select="[list]"></ng-content>
  </div>
  <div class="col-lg-9 h-100 overflow-auto detail" [class.detail-active]="outlet.isActivated">
    <router-outlet #outlet=outlet></router-outlet>
    @if (!outlet.isActivated) {
      <ng-content select="[default]"></ng-content>
    }
  </div>
</div>
